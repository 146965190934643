import React from "react"
import NotesItemBody from "./NotesItemBody"

const NotesItemList = ({ notes, onDelete, onArchive, keyword }) => {
  // keyword && keyword.toLowerCase() digunakan untuk memastikan keyword ada nilainya sebelum mengkonversi ke lowercase
  let filteredNotes = notes.filter((x) =>
    x.title.toLowerCase().match(keyword && keyword.toLowerCase())
  )
  let active = filteredNotes.filter((n) => !n.archived)
  let archive = filteredNotes.filter((n) => n.archived)
  return (
    <>
      <h2>Catatan Aktif</h2>
      <div className="notes-list">
        {active.length > 0 ? (
          active.map((n) => (
            <NotesItemBody
              key={n.id}
              id={n.id}
              title={n.title}
              body={n.body}
              createdAt={n.createdAt}
              cleanDate={n.cleanDate}
              archived={n.archived}
              onDelete={onDelete}
              onArchive={onArchive}
            />
          ))
        ) : (
          <p className="notes-list__empty-message">Tidak ada Catatan</p>
        )}
      </div>

      <h2>Arsip</h2>
      <div className="notes-list">
        {archive.length > 0 ? (
          archive.map((n) => (
            <NotesItemBody
              key={n.id}
              id={n.id}
              title={n.title}
              body={n.body}
              createdAt={n.createdAt}
              cleanDate={n.cleanDate}
              archived={n.archived}
              onDelete={onDelete}
              onArchive={onArchive}
            />
          ))
        ) : (
          <p className="notes-list__empty-message">Tidak ada Catatan</p>
        )}
      </div>
    </>
  )
}

export default NotesItemList
