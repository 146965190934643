import React from "react"
import { getInitialData, showFormattedDate } from "../utils/index"
import InputForm from "./InputForm"
import NotesItemList from "./NotesItemList"

class NotesApp extends React.Component {
  constructor(props) {
    super(props)

    // tambahkan variable tanggal "clean" dalam object
    let data = getInitialData()
    data.map((x) => (x.cleanDate = showFormattedDate(x.createdAt)))

    this.state = {
      notes: data,
    }

    // binding
    this.onDeleteHandler = this.onDeleteHandler.bind(this)
    this.onArchiveHandler = this.onArchiveHandler.bind(this)
    this.onAddNotesHandler = this.onAddNotesHandler.bind(this)
    this.onTypingSearch = this.onTypingSearch.bind(this)
  }

  onDeleteHandler(id) {
    const updatedNotes = this.state.notes.filter((n) => n.id !== id)
    this.setState({ notes: updatedNotes })
  }

  // untuk toggle status archive
  onArchiveHandler(id) {
    let updatedNotes = this.state.notes.map((a) => {
      return { ...a }
    })

    updatedNotes.find((a) => a.id === id).archived = !updatedNotes.find(
      (a) => a.id === id
    ).archived

    this.setState({
      notes: updatedNotes,
    })
  }

  onAddNotesHandler(additional) {
    this.setState({
      notes: [additional, ...this.state.notes],
    })
  }

  onTypingSearch = (e) => {
    this.setState({
      keyword: e.target.value,
    })
  }

  render() {
    return (
      <>
        <div className="note-app__header">
          <h1>Notes App | By Agung</h1>
          <input
            className="note-search"
            placeholder="Cari disini..."
            value={this.state.keyword}
            onChange={this.onTypingSearch}
          />
        </div>
        <div className="note-app__body">
          <InputForm
            onAdd={this.onAddNotesHandler}
            formatDate={showFormattedDate}
          />
          <NotesItemList
            onDelete={this.onDeleteHandler}
            onArchive={this.onArchiveHandler}
            notes={this.state.notes}
            keyword={this.state.keyword}
          />
        </div>
      </>
    )
  }
}

export default NotesApp
