import React from "react"
import DeleteButton from "./DeleteButton"
import ArchiveButton from "./ArchiveButton"

const NotesItemBody = ({
  id,
  title,
  body,
  cleanDate,
  archived,
  onDelete,
  onArchive,
}) => {
  return (
    <div className="note-item">
      <div className="note-item__content">
        <h3 className="note-item__title">{title}</h3>
        <p className="note-item__date">{cleanDate}</p>
        <p className="note-item__body">{body}</p>
      </div>
      <div className="note-item__action">
        <DeleteButton onDelete={onDelete} id={id} />
        <ArchiveButton
          id={id}
          onArchive={onArchive}
          buttonText={archived ? "Pindahkan" : "Arsipkan"}
        />
      </div>
    </div>
  )
}

export default NotesItemBody
