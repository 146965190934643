import React from "react"

class InputForm extends React.Component {
  constructor(props) {
    super(props)
    this.maxTitleLength = 50

    // state initialization
    this.state = {
      title: "",
      body: "",
      cleanDate: "",
      createdAt: "",
    }

    //binding "this"
    this.onTitleChange = this.onTitleChange.bind(this)
    this.onTextChange = this.onTextChange.bind(this)
  }

  onTitleChange(e) {
    if (this.state.title.length > (this.maxTitleLength * 75) / 100) {
      this.setState(() => {
        return {
          textClass: "red",
        }
      })
    } else {
      this.setState(() => {
        return {
          textClass: "",
        }
      })
    }
    this.setState(() => {
      return {
        id: +new Date(),
        title: e.target.value,
      }
    })
  }

  onTextChange = (e) => {
    this.setState(() => {
      return {
        body: e.target.value,
        cleanDate: this.props.formatDate(new Date().toISOString()),
        createdAt: new Date().toISOString(),
      }
    })
  }

  onSubmitEventHanler = (e) => {
    e.preventDefault()
    this.props.onAdd(this.state)
  }

  render() {
    return (
      <div className="note-input">
        <h3>Buat Catatan</h3>
        <p className={`note-input__title__char-limit ${this.state.textClass}`}>
          Sisa karakter: {this.maxTitleLength - this.state.title.length}
        </p>
        <form className="" onSubmit={this.onSubmitEventHanler}>
          <input
            maxLength={this.maxTitleLength}
            type="text"
            placeholder="Ini adalah judul..."
            value={this.state.title}
            onChange={this.onTitleChange}
            autoFocus
          />
          <textarea
            type="textarea"
            value={this.state.body}
            onChange={this.onTextChange}
            placeholder="Tuliskan catatanmu disini..."
            rows="10"
          />
          <button type="submit">Tambah</button>
        </form>
      </div>
    )
  }
}

export default InputForm
